@import '../../../assets/Variables.scss';

.performance {
    button[type='submit'] {
        position: relative;
        background: $gradient;
        color: $white;
        font-family: 'IBMPlexSans';
        font-style: bold;
        padding: 5px;
        border: 0px;
        width: 350px;
        height: 30px;
        text-align: center;
        text-decoration: none;
        display: inline-block;
        box-shadow: 5px 5px 5px #aaa;

        &:active {
            transition: 0.3s all;
            transform: translateY(2px);
            opacity: 0.8;
        }

        @media screen and (max-width: 690px) {
            width: 150px;
            height: 25px;
        }
    }

    .error {
        color: $red;
        margin-left: 16px;
    }
}
