@import '../../assets/Variables.scss';

.global-div {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 80vh;
    margin-bottom: 50px;

    .LogoError {
        position: relative;
        background: $gradient;
        width: 240px;
        height: 200px;
        border-radius: 5px;
    }

    h1,
    h3 {
        position: relative;
        margin: 10px;
    }

    .align-icon {
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        height: 5px;
    }
}
