@import '../../assets/Variables.scss';

.login {
    .Logo42 {
        position: relative;
        width: 170px;
        height: 100px;
        @media screen and (max-width: 600px) {
            width: 150px;
            height: 80px;
        }
    }

    button[type='submit'] {
        position: relative;
        background: $gradient;
        color: $white;
        font-family: 'IBMPlexSans';
        font-style: bold;
        padding: 5px;
        margin: 5px;
        width: 350px;
        height: 30px;
        text-align: center;
        text-decoration: none;
        display: inline-block;
        border: 0px;
        box-shadow: 5px 5px 5px #aaa;

        &:active {
            transition: 0.3s all;
            transform: translateY(2px);
            border: 1px solid transparent;
            opacity: 0.8;
        }

        @media screen and (max-width: 600px) {
            width: 200px;
            height: 25px;
        }
    }
    .error {
        color: $red;
    }
}
